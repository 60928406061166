import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"

import { packageModelsListChangeFilter } from "../../../../../store/actions"
import {
  CheckboxGroup,
  GenderTabsNav,
  SearchInput,
  DateRangePickerField,
} from "../../../../../components"
import { NormalizeUtils } from "../../../../../utils"
import {
  GENDER_FEMALE,
  GENDER_MALE,
  MODEL_AVAILABILITY_STATUS,
} from "../../../../../consts"
import { modelHelper } from "../../../../../helpers"
import i18n from "../../../../../i18n"

const tabs = [
  {
    label: "female",
    value: [GENDER_FEMALE],
  },
  {
    label: "male",
    value: [GENDER_MALE],
  },
  {
    label: "all",
    value: [GENDER_FEMALE, GENDER_MALE],
  },
]

const isInTownOptions = [
  {
    label: (
      <span className={"text-success"}>{i18n.t("in-town.status.in")}</span>
    ),
    value: true,
    color: "secondary",
  },
  {
    label: (
      <span className={"text-danger"}>{i18n.t("in-town.status.out")}</span>
    ),
    value: false,
    color: "secondary",
  },
]

const PackageDataFiltersComponent = props => {
  const { t } = useTranslation()

  const [searchInputValue, setSearchInputValue] = useState("")

  const onSearchInputChange = value => {
    setSearchInputValue(value)
  }

  const handleSearch = value => {
    props.packageModelsListChangeFilter({ keywords: value })
  }

  const onTabChange = value => {
    props.packageModelsListChangeFilter({ genders: value })
  }

  const inTownChange = value => {
    props.packageModelsListChangeFilter({ is_in_town: value })
  }

  const onAvailabilityPeriodChange = value => {
    props.packageModelsListChangeFilter({ availability_period: value })
  }

  const onAvailabilityStatusChange = value => {
    props.packageModelsListChangeFilter({ availability_status: value })
  }

  const period = props.filters.values.availability_period

  const [startDate, endDate] = period

  const invalidPeriod = useMemo(() => {
    return !(startDate || endDate)
  }, [startDate, endDate])

  const availabilityStatusOptions = useMemo(() => {
    return modelHelper.availabilityStatus
      .getAvailabilityStatusOptions()
      .filter(
        item => ![MODEL_AVAILABILITY_STATUS.NOT_AVAILABLE].includes(item.value)
      )
      .map(item => ({
        ...item,
        disabled: invalidPeriod,
      }))
  }, [invalidPeriod])

  return (
    <>
      <div className={""}>
        <SearchInput
          value={searchInputValue}
          onInputChange={onSearchInputChange}
          onSearch={handleSearch}
          placeholder={t("start_typing")}
        />
      </div>

      <div className={"mt-3"}>
        <GenderTabsNav
          tabs={tabs}
          activeTab={props.filters.values.genders}
          onTabChange={onTabChange}
        />
      </div>

      <div className="my-3">
        <CheckboxGroup
          id={"is_in_town"}
          onChange={inTownChange}
          fieldValue={props.filters.values.is_in_town || []}
          options={isInTownOptions}
          normalize={NormalizeUtils.stringBoolToBoolean}
          className={"d-flex form-checkbox-group_horizontal"}
        />
      </div>

      <div className="my-3">
        <DateRangePickerField
          id={"availability_period"}
          label={t("availability_period")}
          placeholder={t("availability_period")}
          value={period}
          onChange={onAvailabilityPeriodChange}
        />
      </div>

      <div className="my-3">
        <CheckboxGroup
          id={"availability_status"}
          onChange={onAvailabilityStatusChange}
          fieldValue={props.filters.values.availability_status || []}
          options={availabilityStatusOptions}
          normalize={NormalizeUtils.stringBoolToBoolean}
          className={"d-flex form-checkbox-group_horizontal"}
        />
      </div>
    </>
  )
}

PackageDataFiltersComponent.propTypes = {
  filters: PropTypes.object,

  packageModelsListChangeFilter: PropTypes.func,
}

const mapStateToProps = state => {
  const { filters } = state.package.models.list.data
  return {
    filters,
  }
}

const mapDispatchToProps = {
  packageModelsListChangeFilter,
}

export const PackageDataFilters = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageDataFiltersComponent)
