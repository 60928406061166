import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from "reactstrap"
import { useDeepCompareEffect } from "react-use"

import { PackageDataFilters } from '../PackageDataFilters';
import { BooksGrid } from './BooksGrid';
import { usePackageDataPicker } from '../../../../../hooks';
import { packageModelsFetchList, packageModelsFetchListCleanState, packageModelsListSetPage } from '../../../../../store/actions';
import { MODEL_AVAILABILITY_STATUS } from 'consts';
import moment from "moment/moment"

const AddBooksContainerComponent = (props) => {
    const { t } = useTranslation();

    const { pickedBooks, onBookToggle } = usePackageDataPicker();

    useEffect(() => {
        return () => {
            props.packageModelsFetchListCleanState();
        }
    }, []);

    useDeepCompareEffect(() => {
        fetchData();
    }, [props.page, props.filters.values]);

    const availabilityPeriod = props.filters.values.availability_period

    const fetchData = () => {
        let params = {
            page: props.page,
            per_page: 8,
        };

        const { keywords, availability_period, availability_status, ...restValues } = props.filters.values;

        const [startDate, endDate] = availability_period

        let filters = {
          ...restValues,
          search_fields: keywords ? ['name', 'surname'] : null,
          with_book: true,
        }

        if (startDate && endDate) {
          filters = {
            ...filters,
            availability_date_start: moment(startDate).format("YYYY-MM-DD"),
            availability_date_end: moment(endDate).format("YYYY-MM-DD"),
            show_fully_available: availability_status.includes(MODEL_AVAILABILITY_STATUS.AVAILABLE) || null,
            show_partly_available: availability_status.includes(MODEL_AVAILABILITY_STATUS.PARTLY_AVAILABLE) || null,
          }
        }

        params.filters = {
          ...filters,
        }

        props.packageModelsFetchList(params);
    };

    const handlePageChange = (item) => {
        props.packageModelsListSetPage(item.selected + 1);
    };

    const onAdd = (book) => {
        onBookToggle(book);
    }

    return (
        <>
            <Row>
                <Col md={8}>
                    <div className={'pe-md-3'}>
                        <BooksGrid
                            items={props.list}
                            loading={props.listLoading}
                            error={props.listError}
                            meta={props.meta}
                            page={props.page}
                            onPageChange={handlePageChange}
                            onAdd={onAdd}
                            picked={pickedBooks}
                            availabilityPeriod={availabilityPeriod}
                        />
                    </div>
                </Col>

                <Col md={4} className={'col-divider_start col-divider_md'}>
                    <div className={'pt-3 ps-md-3'}>
                        <PackageDataFilters />
                    </div>
                </Col>
            </Row>
        </>
    );
};

AddBooksContainerComponent.propTypes = {
    list: PropTypes.array,
    listLoading: PropTypes.bool,
    listError: PropTypes.any,
    meta: PropTypes.object,
    page: PropTypes.any,
    filters: PropTypes.object,

    packageModelsFetchList: PropTypes.func,
    packageModelsFetchListCleanState: PropTypes.func,
    packageModelsListSetPage: PropTypes.func,
}

const mapStateToProps = state => {
    const { list, listLoading, listError, meta, page, filters } = state.package.models.list.data;
    return {
        list, listLoading, listError, meta, page, filters,
    };
};

const mapDispatchToProps = {
    packageModelsFetchList,
    packageModelsFetchListCleanState,
    packageModelsListSetPage,
};

export const AddBooksContainer = connect(mapStateToProps, mapDispatchToProps)(AddBooksContainerComponent);