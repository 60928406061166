import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import * as HOC from "HOC"
import { useFetchData } from "hooks"
import { API } from "api"
import { Calendar } from "./Calendar"

const DataShower = HOC.withDataShowerOverlay()

export const AvailabilityCalendarEvents = ({
  queryParams,
  initialPeriod = [],
}) => {
  const getStartOfMonth = value => {
    const date = value || new Date()

    return moment(date).startOf("month").toDate()
  }

  const [initialStart] = initialPeriod

  const [activeStartDate, setActiveStartDate] = useState(
    getStartOfMonth(initialStart) || null
  )

  const onActiveStartDateChange = value => {
    const date = getStartOfMonth(value)

    setActiveStartDate(date)
  }

  useEffect(() => {
    onActiveStartDateChange(initialStart)
  }, [initialStart])

  const { fetchData, loading, error, data } = useFetchData()

  const onFetch = async () => {
    if (activeStartDate) {
      const dateStart = moment(activeStartDate)
        .startOf("month")
        .add(-1, "week")
        .format("YYYY-MM-DD")

      const dateEnd = moment(activeStartDate)
        .endOf("month")
        .add(1, "week")
        .format("YYYY-MM-DD")

      const params = {
        only_dates: true,
        date_start: dateStart,
        date_end: dateEnd,
        ...queryParams,
      }

      const request = API.calendar.getEvents(params)
      await fetchData({
        request,
      })
    }
  }

  useEffect(() => {
    onFetch()
  }, [activeStartDate])

  const excludeDates = useMemo(() => {
    const items = data?.data || []

    return items.map(item => moment(item).toDate())
  }, [data])

  return (
    <>
      <DataShower isLoading={loading} isFailed={error} error={error}>
        <Calendar
          excludeDates={excludeDates}
          activeStartDate={activeStartDate}
          onActiveStartDateChange={onActiveStartDateChange}
        />
      </DataShower>
    </>
  )
}

AvailabilityCalendarEvents.propTypes = {
  queryParams: PropTypes.any,
  initialPeriod: PropTypes.array,
}
