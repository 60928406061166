import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { IconButton } from "components"
import { modelHelper } from "helpers"

const {
  availabilityStatus: { availabilityStatusesMap },
} = modelHelper

export const ModelAvailabilityStatusIconButton = ({ status, ...props }) => {
  const availabilityStatusConfig = useMemo(() => {
    return {
      theme: availabilityStatusesMap.get(status)?.theme || null,
    }
  }, [status])

  return (
    <IconButton
      name={"calendar"}
      bg
      bgColor={
        availabilityStatusConfig?.theme
          ? `bg-${availabilityStatusConfig?.theme}`
          : null
      }
      {...props}
    />
  )
}

ModelAvailabilityStatusIconButton.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func,
}
