import React from "react"
import PropTypes from "prop-types"

import { ModelAvailabilityStatusIconButton } from "features/fashion-model/FashionModel/components/Elements"
import { AvailabilityCalendarEvents } from "features/calendar/AvailabilityCalendarEvents/components/AvailabilityCalendarEvents"
import { usePopover } from "hooks"
import { Popover } from "components"

export const ModelAvailabilityCalendarControl = ({
  id,
  status,
  initialPeriod,
}) => {
  const { popoverId, toggle, popoverOpen } = usePopover({
    id: id,
  })

  return (
    <>
      <ModelAvailabilityStatusIconButton status={status} id={popoverId} />

      <Popover
        title={"availability"}
        popoverId={popoverId}
        placement={"bottom-start"}
        popoverOpen={popoverOpen}
        toggle={toggle}
      >
        <AvailabilityCalendarEvents
          initialPeriod={initialPeriod}
          queryParams={{
            filter_by: "fashion-model",
            filter_id: id,
          }}
        />
      </Popover>
    </>
  )
}

ModelAvailabilityCalendarControl.propTypes = {
  id: PropTypes.number,
  status: PropTypes.string,
  initialPeriod: PropTypes.array,
}
