import {
    PACKAGE_MODELS_FETCH_LIST,
    PACKAGE_MODELS_FETCH_LIST_SUCCESS,
    PACKAGE_MODELS_FETCH_LIST_ERROR,
    PACKAGE_MODELS_FETCH_LIST_CLEAN_STATE,

    PACKAGE_MODELS_LIST_SET_PAGE,

    PACKAGE_MODELS_LIST_CHANGE_FILTER,
    PACKAGE_MODELS_LIST_RESET_FILTER,
} from './actionTypes';
import { GENDER_FEMALE } from "../../../../../consts"

const initialState = {
    list: [],
    listLoading: false,
    listError: null,
    page: 1,
    meta: null,

    filters: {
        values: {
            keywords: null,
            genders: [GENDER_FEMALE],
            is_in_town: null,
            availability_period: [null, null],
            availability_status: [],
        },
    },
};

const data = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGE_MODELS_FETCH_LIST:
            state = {
                ...state,
                listLoading: true,
                listError: null,
            };
            break;

        case PACKAGE_MODELS_FETCH_LIST_SUCCESS:
            state = {
                ...state,
                listLoading: false,
                list: action.payload.data,
                meta: action.payload.meta,
            };
            break;

        case PACKAGE_MODELS_FETCH_LIST_ERROR:
            state = {
                ...state,
                listLoading: false,
                listError: action.payload,
            };
            break;

        case PACKAGE_MODELS_FETCH_LIST_CLEAN_STATE:
            state = {
                ...state,
                ...initialState,
            };
            break;

        case PACKAGE_MODELS_LIST_SET_PAGE:
            state = {
                ...state,
                page: action.payload,
            };
            break;

        case PACKAGE_MODELS_LIST_CHANGE_FILTER:
            state = {
                ...state,
                page: initialState.page,
                filters: {
                    values: {
                        ...state.filters.values,
                        ...action.payload,
                    },
                },
            };
            break;

        case PACKAGE_MODELS_LIST_RESET_FILTER:
            state = {
                ...state,
                filters: initialState.filter,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default data;