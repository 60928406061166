import i18n from "i18n"
import { MODEL_AVAILABILITY_STATUS } from "consts"

export const getAvailabilityStatusOptions = () => {
  return [
    {
      label: i18n.t("available"),
      value: MODEL_AVAILABILITY_STATUS.AVAILABLE,
      theme: "success",
    },
    {
      label: i18n.t("partly_available"),
      value: MODEL_AVAILABILITY_STATUS.PARTLY_AVAILABLE,
      theme: "warning",
    },
    {
      label: i18n.t("not_available"),
      value: MODEL_AVAILABILITY_STATUS.NOT_AVAILABLE,
      theme: "danger",
    },
  ]
}

export const availabilityStatusesMap = getAvailabilityStatusOptions().reduce(
  (acc, curr) => {
    const { value } = curr

    return acc.set(value, curr)
  },
  new Map()
)
