import React from "react"
import PropTypes from "prop-types"
import { Calendar as ReactCalendar } from "react-calendar"
import "react-calendar/dist/Calendar.css"

export const Calendar = ({
  excludeDates = [],
  activeStartDate,
  onActiveStartDateChange,
  ...props
}) => {
  return (
    <>
      <ReactCalendar
        view={"month"}
        tileDisabled={({ date }) => {
          return excludeDates
            .map(item => item.getTime())
            .includes(date.getTime())
        }}
        activeStartDate={activeStartDate}
        onActiveStartDateChange={({ activeStartDate }) => {
          onActiveStartDateChange(activeStartDate)
        }}
        {...props}
      />
    </>
  )
}

Calendar.propTypes = {
  excludeDates: PropTypes.array,
  activeStartDate: PropTypes.any,
  onActiveStartDateChange: PropTypes.func,
}
